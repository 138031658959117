.notification-panel {
  min-width: 400px;
  background-color: white;

  .notification-status {
    background: #ffffff;
    border-bottom: 1px solid #d4d4d4;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification-block {
    &::-webkit-scrollbar {
      background: transparent;
      width: 10px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 10px;
    }
  
    max-height: 350px;
    max-width: 420px;
    height: auto;
    overflow-y: auto;

    .no-notification {
      align-items: center;
      background: #FAFAFA;
      display: flex;
      height: 350px;
      justify-content: center;
    }

    .loading-indicator {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 50px;
      justify-content: center;
    }

    .unread {
      background: #EFF1F4;
    }

    .read {
      background: #FFFFFF;
    }

    .notification-info {
      border-bottom: 1px solid #D4D4D4;
      padding: 10px;

      .notification-top {
        align-items: baseline;
        display: flex;
        padding-bottom: 5px;

        .title {
          color: #00205B;
          font-weight: bold;
          font-size: 14px;
        }

        .tags-align {
          margin-left: 8px;
        }

        .info {
          background-color: #FFBAE0;
          border-radius: 0.75rem;
        }

        .warning {
          background-color: #FFD9B8;
          border-radius: 0.75rem;
        }

        .timestamp {
          color: #919CB0;
          font-size: 12px;
          margin-left: auto;
        }
      }

      .notification-description {
        color: #14171D;
        font-size: 14px;
      }
    }
  }
}

