@import '../../../variables.scss';
@import '../../../globalMixins';

.maintenance-table-container {
  padding: 0;
  margin-top: 20px;

  .table-mtt {
    [class*=tablesortlabel] {
      p {
        color: $sliders-colors;
      }
    }

    [class*=-head] {

      [role=row] {
        height: auto;
      }

      .skycrown-svg-cls {
        margin: 0;
      }
    }

    margin: 15px 0;
  }

  .title {
    font-weight: bold;
    min-width: 25%;
    font-size: 130%;
  }

  .table-pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #8390a7;
    font-size: 0.875rem;
  }

  [aria-label=tableSearch-selected-filters] {
    justify-content: flex-start;
    gap: 4rem;

    >div:first-of-type {
      flex: unset;
      width: fit-content;
    }

    .chip-style>div {
      height: auto;
    }

    span {
      white-space: normal;
      height: auto;
    }
  }

  div[role=rowgroup] {
    max-width: 100vw;
    overflow-x: scroll;
    position: relative;
    scrollbar-color: #da1884;
    scrollbar-width: thin;
    -ms-overflow-style: none;
  }

  div[role=rowgroup]::-webkit-scrollbar {
    height: 5px;
  }

  div[role=rowgroup]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  }

  div[role=rowgroup]::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #da1884;
  }

  div[role=rowgroup]::-webkit-scrollbar:vertical {
    display: none;
  }
}

.table-value-blur {
  filter: blur(5px);
}