@import '../../mixins.scss';
@import '../../globalMixins.scss';

.task-report-analysis-cls {  
    b{
        display:flex;
    }

    .msn-loader-card {
        min-height: 12.5rem;
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
        
        .msn-loader-center {
            @include loader-center;
        }
    }
    
    .operator-card {
        width: 75%;
        float: left;
        padding-right: 0.5rem;
    }

    .operator-fleet-card {
        width: 25%;
        float: right;
        padding-left: 0.5rem;

        .api-fetching-error > span {
            margin-top: 3rem;
        }
    }

    .task-report-charts {
        width: 50%;
        padding-top: 0.5rem;
    }

    .operator-report-per-year, .operator-number-chart {
        float: left;
        padding-right: 1rem;
    }

    .finding-rate-climatic-area, .fleet-wide-chart {
        float: right;
        padding-left: 1rem;
    }

    .chart-title {
        padding-top: 1rem;
    }

    .multiple-charts-per-row {
        display: inline-flex;
    }  

    .task-report-table {
        padding-bottom: 1rem;

        div > .col-section-cls, .table-filter-chips-cls {
            margin-left: 0.5rem;
        }
    }

    .multiple-chart-on-same-div {
        display: flex;

        .no-data-component-cls {
            margin-top: 3.4rem;
        }
    }

    .range-slider-container {
        div[role=slider] {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    
    @include for-tablet-portrait-up {
    .operator-card,
    .operator-fleet-card,
    .operator-report-per-year,
    .finding-rate-climatic-area {
        width: 100%;
        max-width: 100%;
        flex: auto;
        padding-left: 0;
        padding-right: 0;
    }
    .multiple-chart-on-same-div {
        display: block;
    }
    .taskReportTabs {
        width: 100%;
        float: left;
        display: block;
    }
  }
  .enhanced-toggle-class {
    width: 99%;
    display: flex;
    justify-content: flex-end;
    align-content: baseline;
    margin-top: 15px;
  
    .title-text {
      margin: 1px 0 0 6px;
    }
  
    .info-icon {
      margin: 2px 0 0 2px;
    }
  
    .enhanced-toggle {
      margin: 0 0 4px 19px;
      padding-bottom: 3px;
      width: 36px;
      height: 22px;
    }
  }

  @media (min-width: 1300px) {
    .operator-fleet-card {
        .no-data-component-cls {
            min-height: 1rem;
        }
    }

    .multiple-chart-on-same-div {
        .no-data-component-cls {
            min-height: 1rem;
        }
    }

    .operator-report-per-year, .finding-rate-climatic-area {
        .no-data-component-cls {
            min-height: 23rem;
        }

    }
    div[role=cell]:nth-child(n+6) {
        padding-left: 2rem;
    }
  }
}
