.build-content-cls {
    min-width: 60%;
    background-color: rgb(128 128 128 / 9%);

    .build-list-container {
        height: 78vh;
        width: 95%;
        margin: auto;
        padding: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .spinner-placing {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .build-pagination-cls {
        max-width: 95%;
        min-width: 90%;
        display: flex;
        justify-content: center;
        margin: 10px 0 0 25px;
    }

    .repository-header-analysis-cls {
        width: auto;
        margin: 0 auto;
        margin-right: 2%;

        .repository-search-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.5rem;
            color: rgb(0, 0, 0);
            margin: 2% 0 0 2%;
            font-weight: bold;
        }

        .repository-search-analysis {
            justify-content: end;
            align-items: center;
            margin-right: 30px;
            padding-left: 30px;

            .repository-search-legend-analysis {
                color: rgb(131, 144, 167);
                font-size: 14px;
                max-width: max-content;
                margin-right: 0.8rem;
                display: contents;

                .repository-search-filter-analysis {
                    color: black;
                    padding-right: 6px;
                }

                div {
                    max-width: calc(100% - 3rem);
                    height: fit-content;
                }

                span {
                    white-space: normal;
                    overflow-wrap: anywhere;
                }

                button {
                    min-width: 1rem;
                }
            }

            .repository-search-dropdown-analysis {
                margin: 0 13px 0 13px;
                min-width: 188px;
            }

            .repository-search-dropdown-analysis-2 {
                margin: 0 13px 0 13px;
                min-width: 188px;
            }

            .mpd-repository-search-analysis-button {
                display: contents;
                justify-content: end;
            }
        }
    }

    .repository-paginatation{
        padding: 1rem;
    }
}