.repository-filter-panel {
    min-height: calc(100vh - 56px);
    height: 100%;
    border-width: 1px;
    min-width: 15%;
    max-width: 100%;
    box-shadow: 5px 2px 8px 0px rgba(0, 0, 0, 0.23);
    
    .repo-panel-header {
        margin: 16px 0 0 13px;
        font-size: larger;
    }
    .repo-panel-sub-header {
        margin: 32px 0 0 13px;
    }

    .repo-arrow-button {
        margin-top: 15px;
        svg {
            transform: scale(1.5);
        }
    }

    .repo-arrow-button--open {
        margin-left: 74%;
    }
    
    .repo-arrow-button--close {
        margin-left: 28%;
    }
}