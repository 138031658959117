@import '../../../mixins.scss';

.toast-container-cls {
    position: absolute;

    .repo-tost-cls {
        height: 93.5px;
        margin-left: 1rem;
        width: 21rem;
        margin-bottom: 1px;
        font-size: 13px;

        [class*=heading] {
            font-size: 13px;
        }

        .repo-tost-body-cls {
            word-wrap: break-word;
        }
    }

    .entry-single-toast {
        position: fixed; 
        top: 73%; 
        z-index: 1001; 
        animation-name: toastAnimation; 
        animation-duration: 0.3s;
        
        @include for-big-desktop-up {
            top: 81%;
            animation-name: bigScreenToastAnimation; 
        }
    }

    .entry-first-toast {
        position: fixed; 
        top: 57%; 
        z-index: 1003; 
        animation-name: toastAnimationSecond; 
        animation-duration: 0.3s;
        
        @include for-big-desktop-up {
            top: 63%;
            animation-name: bigScreenToastAnimationSecond; 
        }
    }

    .entry-second-toast {
        position: fixed; 
        top: 73%;  
        z-index: 1002;

        @include for-big-desktop-up {
            top: 81%
        }    
    }

    .entry-third-toast {
        position: fixed; 
        top: 74.5%; 
        z-index: 1001;
        margin-left: 1.5rem;
        width: 20rem;

        @include for-big-desktop-up {
            top: 82.2%
        }
    }
    
    .for-big-desktop-up{
        @include for-big-desktop-up {
            margin-top: 4%;
        }
    }

    .exit-toast {
        animation-name: toastExitAnimation;
        animation-duration: 0.4s;
    }
    
    @mixin toast($width, $leftMargin, $top) {
        width: $width;
        margin-left: $leftMargin;
        top: $top;
        
    }
    
    @keyframes toastAnimation {
        from {
            @include toast(200px, 1.5rem, 80%);
        }
        to {
            @include toast(328px, 1rem, 73%);
        }
    }

    @keyframes bigScreenToastAnimation {
        from {
            @include toast(200px, 1.5rem, 88%);
        }
        to {
            @include toast(328px, 1rem, 81%);
        }
    }

    @keyframes toastAnimationSecond {
        from {
            @include toast(200px, 4rem, 75%);
        }
        to {
            @include toast(328px, 1rem, 57%);
        }
    }

    @keyframes bigScreenToastAnimationSecond {
        from {
            @include toast(200px, 4rem, 75%);
        }
        to {
            @include toast(328px, 1rem, 63%);
        }
    }

    @keyframes toastExitAnimation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}