@import '../../variables.scss';
.mpd-content-cls {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .mpd-content-header {
        color: $mpd_font_color;
        font-weight: bold;
    }

    .icon {
        justify-content: center;
        display: flex;
        font-size: 100px;
        color: #c1c7d3;
    }
}