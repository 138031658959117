@import '../../mixins.scss';

.repository-list-cls {
    max-width: 100%;
    margin-left: 0px !important;
    
    .repository-spinner-cls {
        margin: 23% 0 0 50%;
    }

    .filter-panel-container {
        @include for-desktop-up {
            max-width: 27vw;
        }

        @include for-big-desktop-up {
            max-width: 20vw;
        }
    }

    .closed-filter-panel-container {
        max-width: 5%;
    }

    .repository-content-cls {
        min-width: 60%;
        padding-top: 1.5%;
        background-color: rgb(128 128 128 / 9%);

        .repository-header-cls {
            width: 95%;
            margin: 0 auto;

            .repository-search-container {
                margin-left: auto;
                position: relative;

                .repository-search {
                    justify-content: end;
                    align-items: center;

                    .repository-search-legend {
                        color: rgb(131, 144, 167);
                        font-size: 14px;
                        max-width: max-content;
                        margin-right: 0.8rem;
                        margin-top: 0.3rem;

                        .repository-search-filter {
                            color: black;
                            padding-right: 6px;
                        }
                    }

                    .repository-search-dropdown-2 {
                        margin: 0 13px 0 13px;
                        min-width: 188px;

                        .repository-search-dropdown-2-inline {
                            height: 32px;
                        }

                        .repository-search-dropdown-2-inline>div button {
                            padding: 0px;
                            min-height: 1rem;
                            margin-top: 5px;
                        }

                        .repository-search-dropdown-2-inline input {
                            min-width: 188px;
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left:0%;
                            transform: translateY(-50%);
                        }
                    }
                    .mpd-repository-search-button {
                        display: contents;
                    }
                }
            }
        }

        .repository-list-container {
            width: 95%;
            margin: auto;
        }

        .repository-scroll{ 
            overflow-y: scroll;
        }

        .repository-list-container-default {
            @include for-desktop-up {
                height: 69vh;
            }

            @include for-big-desktop-up {
                height: 80vh;
            }
        }

        .repository-list-container-selection-bar {
            @include for-desktop-up {
                height: 63vh;
            }

            @include for-big-desktop-up {
                height: 75vh;
            }
        }

        .repository-list-container-banner {
            @include for-desktop-up {
                height: 62vh;
            }

            @include for-big-desktop-up {
                height: 75vh;
            }
        }

        .repository-list-container-bar-banner {
            @include for-desktop-up {
                height: 57vh;
            }

            @include for-big-desktop-up {
                height: 71vh;
            }
        }

        .repository-pagination-cls {
            display: flex;
            justify-content: center;
            margin: 1% 0 0 2.5%;
            max-width: 95%;
            min-width: 90%;
            position: sticky;
            top: calc(100% - 60px);

        }

        .repository-pagination-selection-cls {
            display: flex;
            justify-content: center;
            margin: 1% 0 0 2.5%;
            max-width: 95%;
            min-width: 90%;
            position: sticky;
            top: calc(100% - 110px);
        }
    }

    .no-data-cls {
        margin: auto;

        .repository-error-icon {
            height: 39px;
            width: 38px;
            display: block;
            margin-left: 45%;
        }
    }
}