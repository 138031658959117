.quick-link-cls {
    padding-bottom: 1rem;

    .quick-container {
        display: inline-flex;
    }

    .quick-links {
        border-style: solid;
        border-color: #da1884;
        border-radius: 3px;
        display: inline-block;
        background-color: #ffffff;
        margin: 0.5rem;
        cursor: pointer;
        border-width: 2px;
    }

    .quick-links-desc {
        font-size: 0.9rem;
        padding-left: 4px;
        padding-right: 4px;
    }

    .quick-links-title {
        white-space: nowrap;
        display: inline-flex;
        padding-top: 0.5rem;
    }

    .quick-link-icon {
        padding-left: 1px;
        padding-right: 1px;
    }
    a { text-decoration: none; }
}
