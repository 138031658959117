
.scroll-to-top-div {
	opacity: 50%;
	&:hover {
		cursor: pointer;
		opacity: 100%;
		transition: opacity 0.5s;

        .scroll-to-top-text {
            display: inline;
        }
	}
}

.scroll-to-top-btn {
    position: fixed;
    right: 3%;
    bottom: 3rem;
    z-index: 1;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);

    &:hover {
        padding: 1px;
        border: 2px solid #da1884;
        border-radius: 5px;
    }
}

.scroll-to-top-text {
    display: none;
    position: fixed;
    right: 3%;
    bottom: 5.5rem;
    font-size: 0.8rem;
    background-color: #011133;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
}

.scroll-to-top-text::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #011133 transparent transparent transparent;
}

@media (max-width: 1300px) {
    .scroll-to-top-btn {
        transform: scale(0.9, 0.9);
        -ms-transform: scale(0.9, 0.9);
        -webkit-transform: scale(0.9, 0.9);
    }
}