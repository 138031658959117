.smpc-table-controls-cls {

    &.content-right {
        float: right;
    }

    .icon {
        svg {
            vertical-align: middle;
        }
    }

    .mpd-table-search-legend {
        color: rgb(131, 144, 167);
        font-size: 14px;
        max-width: max-content;
        margin: 0.5rem 0.7rem 0 0.1rem;
    }
}