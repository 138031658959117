.warning-icon-data-table-cell-cls {
    &.warning-icon-mpd-table-cell-cls {
        align-items: unset;
        .warning-icon-cell-value {
            margin-top: 2px;
        }
    }

    &.warning-icon-mpd-table-cell-cls>div>div {
        display:inline-block;
    }

    &.warning-icon-mpd-table-cell-cls>div>div>svg[aria-label="data-table-warning-icon"] {
        color: #fabd00;
        width: 1.5rem;
        height: 1.5rem;
        margin-right:10px;
    }
}

.warning-icon-tooltip[role="tooltip"] {
    background-color: #fff;
    color: black;
    opacity: 100%;
}

.warning-icon-tooltip[role="tooltip"]>div:before {
    background-color: #fff;
}