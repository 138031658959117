
.mpd-repository-emtl-delta-card {
    height: 83px;
    box-shadow: 15px 2px 8px 0px rgb(0 0 0 / 23%);
    margin-top: 1rem;
    background-color: white;
    border-radius: 4px;

    .emtl-delta-card-detail-cls {
        margin-left: 24px;
        line-height: 17px;
        .timestamp-cls {
            font-size: 12px;
            color: gray;
        }
    }
    .emtl-delta-card-action-cls {
        display: flex;
        justify-content: flex-end;
        height: 25px;
        margin-top: 25px;
        margin-right: 24px;
        flex-wrap: wrap;

        .action-btn-cls {
            height: 32px;
            border-radius: 3px;
            margin-left: 12px;
            border-color: white;
        }

        .action-btn-cls-download-button{
            color: #da1884;
            border-radius: 3px;
            border: 1px solid #da1884;
        }

        .action-btn-cls-delete-button{
            border-color: rgb(203, 199, 199);
            padding: 0px;
            width: 32px;
        }
        .action-btn-cls-delete-button-icon{
            margin: 0px;
            width: 16px;
            height: 16px;
        }
    }

    #emtl-detla-file-title b {
        margin-right: 2%;
    }

    #emtl-detla-file-title span {
        margin-right: 2%;
        color: rgba(100, 117, 146, 0.5);
    }
 }
