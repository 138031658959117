.mpd-repository-analysis-card {
    box-shadow: 8px 2px 8px 0px rgb(0 0 0 / 23%);
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // overflow-y: hidden;

    p {
        margin: 5px 0;
    }
    .right-panel-newArc{
        display: flex;
        margin-left: auto;
        align-items: center;
        column-gap: 15px;
    }
    .left-panel-newArc{
        display: flex;
        align-items: flex-start;
        width: 100%;
        .details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            width: 490px;
            .creation-updation-time {
                color: #808080;
                margin-top:5px;
                padding:0;
            }
            .status{
                padding:0;
                margin-bottom: -10px;
                margin-top:2px;
            }
            .status-chip{
               margin-top:-30px;
            }
        }
        .rebuild {
            border: 1px black solid;
            height:32px;
        }
        .action-btn-cls{
            height:32px;
        }
        .delete-btn-cls{
            height:32px;
            width:32px;
            border-color: #cbc7c7;
        }
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            p {
                overflow-wrap: break-word;
                word-break: break-word;
            };
            span {
                margin-left: 5px;
            }
        }
        .button-newArc{
            display: flex;
            column-gap: 15px;
        }
    }
    
    .left-panel {
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            p {
                overflow-wrap: break-word;
                word-break: break-word;
            };
            span {
                margin-left: 5px;
            }
        }

        .details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            width: 490px;
            .creation-updation-time {
                color: #808080;
                margin-top:5px;
                padding:0;
            }
            .status{
                padding:0;
                margin-bottom: -10px;
                margin-top:2px;
            }
            .status-chip{
               margin-top:-30px;
            }
        }
        .rebuild {
            border: 1px black solid;
        }
    }

    .right-panel {
        display: flex;
        align-items: center;
        column-gap: 15px;

        .rebuild {
            border: 1px black solid;
            height:32px;
        }
    }
}

@media (max-width: 1200px) {
    .mpd-repository-analysis-card {
        flex-direction: column;

        .right-panel {
            margin-top: 0.5rem;
           justify-content: center;
        }
        .right-panel-newArc {
            margin-top: 0.5rem;
            justify-content: flex-end;
            width: 100%; 
        }
    };
}