.card-compoent-cls {

    padding-top: 1rem;
    padding-bottom: 1rem;

    .card-header {
        line-height: 2rem;
        margin-bottom: 1rem;
    }

    .card-header-left {
        font-size: 1.1rem;
        font-weight: bold;
    }

    .card-premium-toggle, .card-body-value {
        padding-left: 0.5rem;
    }
    
    .card-header-right {
        display: flex;
        justify-content: right;
    }

    .card-body .col {
        padding-bottom : 1rem;
    }
    
    .card-full-screen {
        max-width: 100%;
    }
    
    .card-min-height {
        min-height: 13.5rem;
    }
       
    .display-font-green {
        color: green;
    }

    .display-font-red {
        color: red;
    }

    .loader-center {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    .card-body .left-shift {
        margin-left: -1.5rem;
        margin-right: 1.5rem;
    }

    .min-width-80 {
        min-width: 80px;
    }

    .min-width-120 {
        min-width: 120px;
    }

    .min-width-150 {
        min-width: 150px;
    }

    .min-width-190 {
        min-width: 190px;
    }

    .min-width-210 {
        min-width: 210px;
    }

    .min-width-225 {
        min-width: 225px;
    }

    .min-width-260 {
        min-width: 260px;
    }

    .width-200{
        width:200px;
    }

    [role=group]{
        font-size: smaller;
    }
    .taskLevel{
        padding-left: inherit;
    }
    .width-190{
        width:200px;
    }
    @media (min-width: 1300px) {
        .width-190{
        width:250px;
        }
      }
    
}



