.dialog-component-cancel-button {
    color: #da1884;
    border-radius: 2px;
    border: 1px solid #da1884;
}

.dialog-header {
    font-weight: bold;
}

.delete-file-title{
    margin-top: 11px;
    word-wrap: break-word;
}

.delete-file-title b {
    margin-right: 2%;
}

.delete-file-title span {
    margin-right: 2%;
    color: rgba(100, 117, 146, 0.5);
}