.enhanced-cal {
  width: 99%;
  display: flex;
  justify-content: flex-end;
  align-content: baseline;
  margin-top: 15px;

  .title-text {
    margin: 1px 0 0 6px;
  }

  .info-icon {
    margin: 2px 0 0 2px;
  }

  .enhanced-toggle {
    margin: 0 0 4px 19px;
    padding-bottom: 3px;
    width: 36px;
    height: 22px;
  }
}

.no-ue-table-data-title {
  font-weight:bolder; 
  font-size:larger; 
  color:#282e3a;
  padding-bottom:15px;
}

[class*="ds-card"].card--grey {
  background-color: #EFF1F4;
}

.row-graphs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: nowrap;
  column-gap: 20px;

  .graph-size {
    flex-basis: 48%;
    flex-grow: 2;
    display: inherit;
    width: 50vw;
  }

  .graph-card {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    max-width: 100%;

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }

    .card-title {
      display: flex;
      place-self: start;
      font-size: 1rem;
      margin: 0;
      font-weight: 600;
      color: rgb(100, 117, 146);
      padding: 5px 0 0 27px;
    }

    .card-content {
      border-top: solid 0.05rem rgb(230, 230, 230);
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      .warning {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .enhanced-analysis-off-text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      color: rgb(80, 93, 116);
    }
  }
}

.unschedule-table-title {
  margin-left: inherit;
  width: fit-content;
  display: flex;
}

div:has(>div>.unschedule-table-title) {
  align-items: unset !important;
}

.unsched-events-table {
  .table-filter-chips-cls{
    margin:unset;
    padding: 0 0.5rem 1rem 0;
  }
  &.custom-container {
    padding: 0;
  }

  .table-control-cls, .col-section-cls {
    margin-left: 1px;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  [role=table] {
    min-height: fit-content !important;
  }

  [class*=-head] {
    [role=row] {
      min-height: fit-content;
      padding: 0.2rem 1rem 0.5rem;
    }

    [role=columnheader] {
      padding-left: 0;
    }
  }

  div[role=rowgroup] {
    height: 100% !important;
  }

  [class*=-body] {
    [role=row] {
      min-height: 35px;
      height: auto;
    }
  }

  div[role=cell]:nth-child(5), div[role=cell]:nth-child(6) {
    display: block;
  }

  [aria-label=genericTable-pagination] {
    margin-top: 1rem;
    border-bottom: solid transparent 1rem;
  }

  input[aria-label=table-search-searchbox] {
    width: 25rem;
  }

  [role = presentation] {
    align-items: center;
    padding: 0.7em;
    display: flex;
  }

  margin: 15px 0;
}
