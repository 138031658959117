.banner-list {
    position: sticky;
    position: -webkit-sticky;
    top:0;
    z-index: 1200;
}

.banner-cls {
    .banner-customisation {
        width: 100%;
        white-space: pre-wrap;
    }
    .banner-message {
        white-space: pre-wrap;
        flex-basis: 87%;
    }

    .banner-message a {
        color:inherit;
    }

    &:has(.custom-banner-icon) {
        &:before {
            content:none;
        }
    
        .custom-banner-icon {
            display: inline;
            position: absolute;
            left: 0.6em;
            width: 2.65em;
            height: 1.8em;
        }
    }

    .banner-link {
        color: white;
    }
}