@import 'variables.scss';
@mixin drawer-arrows() {
    float: right;
    svg {
        transform: scale(1.5);
    }
}

@mixin drawer-opened() {
    padding: 16px;
    min-width: 305px;
    width: 27vw;
    margin-top: 9vh;
    .arrow-button {
        @include drawer-arrows;
    }
}

@mixin basic-flex-center() {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@mixin sliders($color-track, $color-thumb) {
    [class*=thumb] {
        background-color: $color-thumb;
    }

    [class*=track] {
        background-color: $color-track;
    }

    [class*=tick] {
        white-space: nowrap;
    }
}

@mixin radioDescription {
    font-size: $mpd_radiobtn_des_fontsize;
    margin-top: -6px;
    margin-left: 24px;
}

@mixin basic-flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin: 0 calc(-1rem);
}

@mixin basic-flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 0;
}

@mixin loader-center {
    position: absolute;
    left: 50%;
    margin-top: 5rem;
}