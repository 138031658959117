.data-table-cell-cls {
    overflow-wrap: break-word;

    p {
        margin-top: 3px;
        margin-bottom: 3px;
        white-space: pre-line;        
    }

    .data-table-cell-link {
        font-size: 0.9rem;
        padding-top: 0px;
        padding-bottom: 0px;
        text-decoration: none;
        padding-right: 0px;
        float: right;
    }
}