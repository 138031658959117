.smpc-table-hidden-cols-cls {
    .icon {
        svg {
            vertical-align: middle;
        }
    }

    .content-right {
        display: flex;
        margin-left: auto;
    }

    .bold-title{
        font-weight: bold;
    }
    
}
