.chart-screen-cls {
    .chart-spacing {
        padding: 20px;
    }

    .chart-screen {
        max-width: 100%;
    }

    .range-slider-container {
        padding: 20px;
    }
}
