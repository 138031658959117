.compare-filter-dialog-cls {

  &.compare-filter-dialog-cls-custom {
    height: 573px;
    max-height: 573px;
    max-width: 948px;
  }

  .compare-filter-dialog-headers {
    color: rgb(0, 27, 77);
  }
  
  .reference-file-chip {
    color: #282e3a;
    font-weight: bold;
    margin-left: 1.1rem;
  }

  .status-chip {
    font-weight: bold;
    width: 3rem;
  }
  .compare-filter-table {
    margin-bottom: 0.8rem;
    margin-top: 0.3rem;
  }
}
