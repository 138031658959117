@import '../../variables.scss';

.mpd-container-cls{
    .cust-col-main-frame {
        background-color: $main-bg-color;
        width: 80%;
        padding-top: 10px;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }
    
    .mpd-col-drawer {
        max-width: 5vw;
        border-right: 1px solid #e2e2e2;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }
}
