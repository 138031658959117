.table-search-cls {
    justify-content: space-between;

    .table-search-user-input-box {
        width: 100%;
        height: 32px;
        margin-right: 20px;
    }

    .table-search-search-button {
        width: 100%;
        height: 32px;
    }

    .table-search-legend {
        color: rgb(131, 144, 167);
        font-size: 14px;
        max-width: max-content;
        margin: 0.3rem 0.5rem 0 0.1rem;
    }
}

.chip-reset-container {
    display: flex;
    height: auto;
    justify-content: flex-start;
    align-items: center;
}

.chip-style {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    flex-wrap: wrap;
    width: auto;
}

.reset-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-btn {
    margin: 0.5vh 0 0 0.5vh;
    gap: 0.5vw;
}

.chip-individual {
    white-space: normal;
    margin: 0.5vh 0 0 0.5vh;
    height: fit-content !important;

    span{
        white-space: normal;
    }

    button{
        padding: 0.625rem;
    }
}
