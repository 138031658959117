
.mpd-task-table-cls {

    div[role=row] {
        height: auto;
    }

    div[role=cell] {
        display: block;
        margin-left: unset;
        margin-top: 2px;
    }

    div[role=columnheader] {
        flex: auto;
    }

    div[role=rowgroup] {
        height: 100% !important;
    }

    p {
        &.mpd-table-data-cell {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .mpd-emtl-table-spinner-cls {
        margin: 15% 50%;
        margin-left: 50%;
    }

    .mpd-emtl-table-filter-group-cls {
        .icon {
            svg {
                vertical-align: middle;
            }
        }
    
        .content-right {
            display: flex;
            margin-left: auto;
        }

        .bold-title {
            font-weight: bold;
        }

        .table-filter-chip {
            white-space: normal;
            span {
                max-width: 32rem;
            }
        }
    }
    
    &.custom-container {
        padding: 0px;
    }

    .margin-table {
        margin: 15px 0px;
        min-width: 100% !important;
    }

    .icon-button {
        padding: 7px 7px;
    }

    div {
        &.icon-button {
            padding: 0px;
        }
    }

    .clear-all-btn-icon {
        margin-left: 10px;
        margin-bottom: 3px;
        svg {
            vertical-align: middle;
        }
    }

    .extra-margin-top {
        margin-top: 16px;
    }
}
