.app-header-cls {
  li {
    a {
      padding: calc(0.5rem - 1px) 0.5rem;
      border: none;
    }
  }

  button.btn-font-small {
    div {
      font-size: 0.7rem;
    }
  }
  .help-button-link {
    padding: 0;
    color: #ffffff;
  }
  .profile-btn-cls {
    background-color: transparent;
    color: white;
  }
  .profile-btn-cls:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  .chevron-style-cls {
    margin-left: 7px;
  }
}

.show-badge {
  bottom: 10px;
}

.notification-container {
  position: absolute;
  top: 60px;
  right: 10px;
  border: 1px solid #E0E3E9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1700;
}
