.spinner {
   .spinner-container-blocking {
      position: absolute;
      background-color: #ffffff73;
      height: 99%;
      width: 100%;
      display: flex;
      z-index: 1;
   }
   
   .spinner-wrapper {
      margin: auto;
   }
   .spinner-container-nonblocking {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }
}
