.appl-ovrv-1 {
  padding-left: 6.25rem;
  padding-top: 0.625rem;
  .appl-ovrv-2 {
    padding-top: 0.625rem;
    padding-left: 0.625rem;
    .appl-ovrv-str-1 {
      font-size: 1.5rem;
      color: rgb(0, 45, 128);
    }
  }
}

.appl-card-outer {
  padding-left: 6.25rem;
  padding-top: 0.625rem;
  .appl-card {
    padding: 0.625rem;
  }
}
