@import '../../../mixins.scss';
.header-info-tooltip {
  background-color: #fff;
  color: black !important;
  @include for-desktop-up {
    max-width: 23vw;
  }
  @include for-big-desktop-up {
      max-width: 17vw;
  } 
  opacity: 100% !important;

  .header-info-dot {
    height: 14px;
    width: 10px;
    border-radius: 2px;
    background-color: #acb5c2;
    display: inline-block;

    &.success {
      background-color: #255fcc;
    }

    &.error {
      background-color: #f23346;
    }
  }

  .delta-no-change{
    color: #b0b8c4;
  }

  .delta-added{
    color: #63728a;
    font-weight: bolder;
  }

  .delta-deleted{
    color: #63728a;
    text-decoration: underline solid;
  }
}

.header-info-tooltip,
.header-info-tooltip-arrow:before {
  background-color: #ffffff !important;
  margin-top: -8px;
}

.header-info-tooltip>div {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.header-info-tooltip>div>div {
  margin-bottom: 0.5rem;
}

.header-info-tooltip>div>div>div>div {
  padding-left: 1rem;
}