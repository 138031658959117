.homepage-disclaimer-dialog-cls {

  &.homepage-disclaimer-dialog-cls-custom {
    height: 573px;
    max-height:527px;
    max-width: 882px;
  }

  &.homepage-disclaimer-dialog-cls-custom>button[aria-label="Close"] {
    display: none;
  }

  .homepage-disclaimer-footer {
    display: flex;
    justify-content: space-between;
    margin-left: 1.5rem;

    .homepage-disclaimer-checkbox {
      &.homepage-disclaimer-checkbox-custom>label {
        font-weight: bolder;  
      }
    }

    .homepage-disclaimer-button {
      margin-right: 1.5rem;
    }
  }
}