@import '../../mixins.scss';

.chart-screen-cls {

    margin-top: 1rem;
   
    .chart-spacing {
        padding: 20px;
    }

    .tabs-styling {
        width: 60%;
        float: left;
        padding: 5px;
    }

    .fleet-height {
        min-height: 4rem;
    }

    .chart-screen {
        max-width: 100%;
        min-height: 12.5rem;
    }

    .finding-type {
        position: absolute;
        right: 225px;
        margin: -1%;
        width: 10rem;
    }

    .finding-type-dropdown {
        width: 16rem;
    }

    .finding-type-label-text {
        position: relative;
        right: 10px;
        bottom: 4px;
        font-weight: 700;
    }

    .range-selection {
        position: absolute;
        right: 50px;
        margin: -1.1%;
    }

    .loader-center {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    @include for-tablet-portrait-up {
        .operator-report-per-year,
        .finding-rate-climatic-area {
            width: 100%;
            max-width: 100%;
            flex: auto;
            padding-left: 0;
            padding-right: 0;
        }
        .chart-tab {
            width: 100%;
            float: left;
            display: block;
        }
        .chart-tabs {
            width: 100%;
            right: 0;
            margin: 0;
            display: block;
            z-index: 10;
        }
        .finding-type {
            width: 100%;
            right: 0;
            margin: 0;
            position: relative;
            padding: 0.5rem;
            z-index: 1;
        }
    }

}
