.mpd-repository-selected-bar {
    .toast-style-cls {
        margin-left: 2rem;
        position: fixed;
        width: 22rem;
        bottom: 68px;
    }
    .mpd-repository-selected-files-card {
        height: 50px;
        background-color:#da1884;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-right: 2%;

        .mpd-repository-selected-files-buttons {
            display: flex;
            margin-left: auto;
        }

        .mpd-repository-selected-files-content-center {
            width: max-content;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 0.8rem;
            color: white;
            text-align: center;
            background-color: #ae1369;
        }

        .mpd-repository-selected-files-content-center svg{
            display: inline;
            justify-content: center;
            margin-left: 18px;
            height: 22px;
            width: 22px;
            vertical-align: top;
        }

        .disabled{
            color: #ffffff70
        }

        .mpd-repository-selected-files-compare-button {
            display: flex;
            margin-left: auto;
            border-radius: 2px;
            border-color: #da1884;
            color: #da1884;
            background-color: white;
            
            .action-btn-selected-files-cls {
                height: 1rem;
                margin-right: 1rem;
                
            }
        }

        .card-action-selected-files-cancel-button-cls {
            display: flex;
            margin-left: auto;
            margin-right: 10px;
            color: white;
            background-color: #da1884;
        }

        .card-action-selected-files-cancel-button-cls:hover {
            background-color: #da1884;
        }
    }
}