@import '../../../mixins.scss';
@import '../../../variables.scss';
.emtl-delta-view-soc-container-cls {

  .delta-soc-page-title {
    position: absolute;
    // For back-button clicking since its absolute
    z-index: 1;
  }

  &.custom-container {
    padding-left: 32px;
    padding-right: 32px;
    height: 100%;
  }

  .emtlDeltaViewSOCBackBtn {
    margin-left: 0px;
    margin-right: 0px;
    border: none;
  }
}

.emtl-delta-view-soc-table {

  &.custom-container {
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  p {
    &.data-table-header-title {
      margin-top: 0px;
      margin-bottom: 0px;
      color: $soc_header_color;
    }
  }

  div {
    &.data-table-header-icon {
      color: $soc_header_icon_color;
    }
  }

  div[role=row] {
    height: auto;
  }

  .mvt-chip {
    width: 3rem;
    height: 1.5rem; 
    font-weight: bold;
  }

  div[role=cell] {
    display: block;
    margin-left: unset;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
  }

  div[role=columnheader] {
    flex: auto;
    margin: 5px 0 11px 0;
  }

  div[role=rowgroup] {
    height: 100% !important;
    display: flex;
    flex-direction: column;
  }

  .margin-table {
    margin: 15px 0px;
    min-width: 100% !important;
    height: 67vh !important;

    @include for-big-desktop-up{
      height: 76vh !important;
    }
    
    &.custom-container {
      height: 24rem;
    }
  }

  .extra-margin-top {
    margin-top: 16px;
  }

  .table-search-custom {
    div[role=combobox] {
      padding-right: 52px;
    }
    .table-search-user-input-box{
      min-width: 140px;
      margin-right: 40px;
    }

    svg {
      color: #063b9e;
      height: 21px;
      width: auto;
    }
  }
}