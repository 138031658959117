@import '../../variables.scss';
@import '../../mixins.scss';

.mpd-col-main-frame {
    width: 45%;
    padding: 10px 10px;
    min-width: 300px;

    .smpc-chart-container-content-col1{
        width: 43%;
        .emtl-chart-container-content-col1{
            margin-left: 25px;
            flex: 0 0 90%;
        }
    }
}

.align-emtl-general-info-cls{
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 10px;
    font-weight: bold;
}

.modal-display-style{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modal-style-zoom{
        width: 500px;
        min-height: 350px;
        padding: 10px;
        background-color: white;
        border-radius: 3px;
        position: relative;

        .modal-container{
            height: 85%;
            position: absolute;
            width: inherit;
        }
        .modal-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.mpd-emtl-general-info-col {
    width: 45%;
    padding: 10px 10px;
    min-width: 300px;
    min-height: 70px;

    .smpc-chart-container{
        width: 100%;
        min-width: 100% !important;
        height: 100%;
        max-height: 400px;

        .emtl-chart-container-content-row-spacing{
            padding-top: 11px;
            margin: auto;
        }

        .bold-title{
            font-weight: bold;
        }

        .title-right{
            margin-left: 20%;
        }

        .smpc-chart-container-content-col1{
            width: 100%;
            margin-top: 10px;
            margin-right: 40px;
            .emtl-chart-container-content-col1{
                margin-left: 25px;
                flex: 0 0 90%;
            }
            .emtl-chart-container-content-col1-established-from{
                display: table-cell; width: 100%; white-space: normal;
            }
        }

        #estFrom>span {
            @include for-desktop-up {
                margin-right:58px;
            }

            @include for-big-desktop-up {
                margin-right: 30px;
            }
        }

        #estFrom>span:first-child {
            @include for-desktop-up {
                margin-right: 124px;
            }

            @include for-big-desktop-up {
                margin-right: 30px;
            }
        }

    }


}
