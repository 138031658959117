@import '../../../mixins.scss';

.tab-ui-cls {
  background-color: #e2e9ed;

  @include for-tablet-portrait-up {
    .taskReportTabs {
      width: 100%;
      float: left;
      display: block;
    }

    .tab-ui {
      width: 100%;
      display: block;
    }
  }
}