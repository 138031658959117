.col-main-content {
    min-height: 91vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    .button-div {
        margin-top: 20px;
    }
}

.col-main-content h5 {
    color: black;
}

.col-main-content .icon {
    justify-content: center;
    display: flex;
    font-size: 100px;
    color: #c1c7d3;
}