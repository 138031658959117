@import '../../mixins.scss';

.smpc-chart-container-content-col1-delta {

    &.smpc-chart-custom-container {
        width: 43%;
        min-width: 100%;
        margin-top: 20px;
        min-height: 164px;
    }

    .emtl-chart-container-content-col1-delta {

        &.emtl-chart-custom-container {
            flex: 0 0 90%;
            padding-right: 20px;

            .emtl-delta-reference-file-chip {
                margin-top: 2%;
            }
        }

    }

    .emtl-chart-container-content-col1-delta>div>ul>li a:before {
        border-bottom: 1px solid #da1884;
    }

    .emtl-chart-container-content-row-spacing-delta {

        &.emtl-chart-custom-container-spacing {
            margin-top: 20px;
            margin-bottom: 20px;
            padding-right: 20px;

            .estFromHeader {
                @include for-desktop-up {
                    max-width: 12%;
                }
        
                @include for-big-desktop-up {
                    width: 100%;
                }
            }

            .emtl-chart-container-content-col-established-from{
                display: table-cell; width: 100%; white-space: normal;
            }

            .estFromTitle:nth-of-type(odd) {
                @include for-desktop-up {
                    margin-right: 23px;
                }
    
                @include for-big-desktop-up {
                    margin-right: 20px;
                }
            }
            .estFromTitle:nth-of-type(even) {
                @include for-desktop-up {
                    margin-right: 0px;
                }
    
                @include for-big-desktop-up {
                    margin-right: 20px;
                }
            }

            .estFromTitle:first-child {
                @include for-desktop-up {
                    margin-right: 80px;
                }
    
                @include for-big-desktop-up {
                    margin-right: 20px;
                }
            }

            #estFromDelta{
                @include for-desktop-up {
                    width: 100%;
                }
        
                @include for-big-desktop-up {
                    width: 110%;
                }
            }
        }

        .bold-title {
            font-size: 16px;
        }
    }
}