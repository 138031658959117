.fleet-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .fleet-container-outer {
    padding: 0.625rem 7.25rem;
    background: rgb(240, 244, 246);
    .fleet-row {
      width: calc(100% + 3.5rem) !important;
      .fleet-typo-fleet {
        .welcome-typo {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          .fleet-typo-fleet-strong {
            font-size: 1.25rem;
          }
        }
      }
      .column-xxs-4 {
        .asterik-icon-container {
          height:60%;
        }
        .asterik-icon {
          width: 0.7em;
          align-items: start;
          color: #da1884;
        }
        .fleet-typo {
          display: flex;
          justify-content: flex-end;
          .fleet-typo-strong {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            color: rgb(80, 93, 116);
          }
        }
        .fleet-dropdown {
          padding: 0.313rem;
          div[role="combobox"] {
            background-color: rgb(255, 255, 255);
          }
        }
        .fleet-operator-image {
          .image-component {
            position: absolute;
            right: 0;
            bottom: 0;
            display: inline-flex;
            background-color: rgb(255, 255, 255);
            width: 4.5rem;
            height: 2.5rem;
            justify-content: center;
          }
          .operator-image {
            margin: auto;
          }
        }
      }
      .column-xxs-1 {
        padding-left: 0.313rem;
        display: inline-flex;
        .confirm-button {
          margin-top: 2.563rem;
        }
        .info-tooltip-target {
          display: inline;
          position: relative;
          top: 1.875rem;
        }
      }
    }
  }
}
