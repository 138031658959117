
.mpd-repository-card {
    height: 83px;
    box-shadow: 15px 2px 8px 0px rgb(0 0 0 / 23%);
    margin-top: 1rem;
    background-color: white;
    border-radius: 4px;

    &.mpd-repository-card-pre-rev {
        margin-bottom: 50px;
    }

    .card-detail-cls {
        margin-left: 24px;
        line-height: 17px;
        .timestamp-cls {
            font-size: 12px;
            color: gray;
        }
    }
    .card-action-cls {
        display: flex;
        justify-content: flex-end;
        height: 25px;
        margin-top: 25px;
        margin-right: 24px;
        flex-wrap: wrap;

        .action-btn-cls {
            height: 32px;
            margin-left: 12px;
            border-color: white;
        }

        .action-btn-cls-delete-button{
            border-color: rgb(203, 199, 199);
            padding: 0px;
            width: 32px;
        }
        .action-btn-cls-delete-button-icon{
            margin: 0px;
            width: 16px;
            height: 16px;
        }
    }

    .previous-revision-bar{
        display: flex;
        justify-content: space-between;
        background-color: #e5ecf7;
        .previous-revision-text{
            padding-left: 23px;
            padding-top: 10px;
        }
        .previous-revision-button{
            color: #1346a3;
            padding-right: 24px;  
            padding-top: 2px;
            padding-bottom: 2px;  
            width: 153px;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
 }
