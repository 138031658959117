.emtl-delta-view-container-cls {

  &.emtl-custom-container {
    padding-left: 32px;
    padding-right: 32px;
  }

  .emtlDeltaViewContainer-button {
    margin-left: 0px;
    margin-right: 0px;
    border-color: rgb(244 244 244);
  }

  .emtlDeltaViewContainer-premium-button {
    margin-right: 4px;
  }

  .emtlDeltaViewContainer-bookmark-button {
    margin-left: 4px;
  }
}

.emtl-delta-view-table {

  &.custom-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  p {
    &.data-table-header-title {
      margin-top: 0px;
      margin-bottom: 0px;
      color: #43577c;
    }
  }

  div {
    &.data-table-header-icon {
      color: #43577c;
    }
  }

  div[role=row] {
    height: auto;
  }

  div[role=cell] {
    display: block;
    margin-left: unset;
    margin-top: 2px;
  }

  div[role=columnheader] {
    flex: auto;
  }

  div[role=rowgroup] {
    height: 100% !important;
  }

  .margin-table {
    margin: 15px 0px;
    min-width: 100% !important;
  }

  .extra-margin-top {
    margin-top: 16px;
  }

  .table-search-custom {
    div[role=combobox] {
      padding-right: 58px;
    }

  .table-search-user-input-box {
      margin-right: 0;
  }

    svg {
      color: #063b9e;
      height: 21px;
      width: auto;
    }
  }

}