.crown-svg-cls {
  display: inline;
  
  .crown-svg {
    fill: currentColor;
    width: 2rem !important;
    height: 0.8rem;;
    display: inline-flex;
    font-size: 1rem;
    padding-right: 0.5rem;
  }
}
