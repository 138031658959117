.table-filter-chips-cls {
    .icon {
        svg {
            vertical-align: middle;
        }
    }

    .content-right {
        display: flex;
        margin-left: auto;
    }

    .bold-title {
        font-weight: bold;
    }

    .table-filter-chip {
        white-space: normal;
        span {
            max-width: 32rem;
        }
    }
}