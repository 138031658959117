@import '../../../variables.scss';
@import '../../../mixins.scss';

.mpd-repository-emtl-content-cls {
    @include for-desktop-up {
        min-height: 83%;
    }

    @include for-big-desktop-up {
        min-height: 89%;
    }
    
    display: flex;
    margin:0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;

    .mpd-repository-emtl-content-header {
        color: black;
        font-weight: bold;
    }

    h6 {
        margin-bottom: 0.2rem;
        margin-top: 0.5rem;
    }
    .mpd-repository-emtl-content-sub-header {
        color: #90949a;
    }

    .mpd-repository-emtl-icon {
        svg {
            margin:0 auto;
            display: flex;
            height: 100px;
            width: 100px;
            color: #c1c7d3;
        }
    }
}