.data-table-cell-cls {
    &.mpd-table-cell-cls {
        a {
            padding: 0;
            float: left;
            border: none;
        }
        a:hover {
            text-decoration:underline;
        }
    }
}

.tooltip-mpd-data-cell {
    margin-top: 1rem;
}

.tooltip-mpd-data-cell[role="tooltip"] {
    background-color: #fff;
    color: black;
    opacity: 100%;
}

.tooltip-mpd-data-cell[role="tooltip"]>div:before {
    background-color: #fff;
}