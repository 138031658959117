.data-table-header-title {
    white-space: normal;
    font-weight: bold;
}

.data-table-header-icon {
    cursor: pointer;
}

.tooltip-icon-align {
    height: 14px;
    margin-left: 4px;
}