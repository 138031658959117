@import '../../mixins.scss';
@import '../../globalStyles';
@import '../../globalMixins.scss';

.eco-assessment {
  .chart-card {
    width: 100%;
    margin-top: 2vh;
    min-height: 30%;
    max-width: 100%;

    .card-header {
      color: #6a79a4;
    }

    .card-content {
      padding: 1.5rem;
      min-height: 45vh;
    }

    .pie-chart {
      height: 300px;
      width: 300px;
    }
  }
  
  .eco-access-banner {
    ::before {
      bottom: 2.3rem;

      @include for-big-desktop-up {
        bottom: 1.5rem;
      }
    }

    button[class$="-remove-btn"] {
      bottom: 1.2rem;
      width: 2.2rem;
      
      @include for-big-desktop-up {
        bottom: 0.7rem;
      }
    }
  }
}

.eco-assess-modal {
  @include basic-flex-center;
  .card {
    width: 90%;
    max-width: 90%;
    height: 90%;

    .card-header {
      color: #6a79a4;
    }
  
    .card-content {
      padding: 1.5rem;
      height: inherit;
    }
  }
}

.disclaimer {
  margin-top: 1rem;
}

.no-eco-assessment{
  font-weight: 600;
  font-style: italic;
}

