.homepage-cards {
  max-width: 50rem !important;
  .card-comp-outer {
    min-height: 15.625rem;
    max-width: 15.625rem;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    .card-comp-title {
      min-height: 3.125rem;
      .card-comp-title-strong {
        font-size: 1rem;
      }
    }
    .card-comp-desc {
      min-height: 6.25rem;
      .card-comp-desc-para {
        font-size: 0.875rem;
      }
    }
    .card-actions {
      justify-content: flex-start;
    }
  }

  .wider-card-comp-outer {
    height: 16.8125rem;
    max-height: 16.8125rem;
    width: 49.32rem;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
    .card-content {
      padding-bottom: 0;
      .card-comp-title {
        min-height: 3.125rem;
        .card-comp-title-strong {
          font-size: 1rem;
        }
      }
      .card-comp-desc {
        min-height: 1.5625rem;
        .card-comp-desc-para {
          font-size: 14px;
        }
      }
    }
    .card-actions {
      .btn-row {
        margin: 0;
        margin-top: 5.8rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        .card-buttons {
          margin: 10px 10px 5px 0;
          border: 1px solid rgb(218, 24, 132);
          padding: 2px 10px;
          color: rgb(218, 24, 132);
          font-size: 14px;
          font-weight: bold;
          height: 2rem;
          letter-spacing: 0px;
          line-height: 24px;
          text-align: center;
          flex-grow: 1;
          flex-flow: column-reverse;
        }
        .open-new-page-icon {
          width: 24px;
          height: 24px;
          padding-top: 8px;
          position: relative;
          bottom: -3px;
        }
      }
    }
  }
}
