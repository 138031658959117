.mpd-dialog-cls {
  &.mpd-dialog-cls-custom {
    height: fit-content;
    max-height: 573px;
    max-width: 90%;
  }

  .mpd-dialog-divider{
    margin-bottom: 0;
  }
  .mpd-dialog-headers {
    color: rgb(0, 27, 77);
  }

  .reference-file-chip {
    color: #282e3a;
    font-weight: bold;
    margin-left: 1.1rem;
  }

  .status-chip {
    font-weight: bold;
    width: 3rem;
  }

  .mpd-dialog-table {
    margin-bottom: 0.8rem;
    margin-top: 0.3rem;

    div[role=row] {
      height: auto;
      min-height: 3rem;
  }

  div[role=cell] {
      display: block;
      margin-left: unset;
      margin-top: 2px;
  }

  div[role=columnheader] {
      flex: auto;
      height: 3rem;
  }

  div[role=rowgroup] {
      height: 100% !important;
  }

  p {
      &.mpd-table-data-cell {
          margin-top: 0px;
          margin-bottom: 0px;
      }
  }

  .mpd-emtl-table-spinner-cls {
      margin: 15% 50%;
      margin-left: 50%;
  }

  .mpd-emtl-table-filter-group-cls {
      .icon {
          svg {
              vertical-align: middle;
          }
      }
  
      .content-right {
          display: flex;
          margin-left: auto;
      }

      .bold-title {
          font-weight: bold;
      }

      .table-filter-chip {
          white-space: normal;
          span {
              max-width: 32rem;
          }
      }
  }
  
  &.custom-container {
      padding: 0px;
  }

  .margin-table {
      margin: 15px 0px;
      min-width: 100% !important;
  }

  .icon-button {
      padding: 7px 7px;
  }

  div {
      &.icon-button {
          padding: 0px;
      }
  }

  .icon {
      margin-left: auto;
      svg {
          vertical-align: middle;
      }
  }

  .extra-margin-top {
      margin-top: 16px;
  }
  }
}