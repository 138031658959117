.mpd-repository-download-menu {

    .download-button {
        height: 32px;
        margin-left: 12px;
        color: #da1884;
        border-radius: 3px;
        border: 1px solid #da1884;
    }

}

ul[role="menu"].mpd-repository-download-menu-popover {
    min-width: 170px;
}
