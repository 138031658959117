@import "../../globalMixins";

.col-drawer {
  @include basic-flex-column;

  border-right: 1px solid #e2e2e2;
  height: -3.5rem;
  max-width: 4rem;
  align-content: center;
}

.row-program-analysis {
  @include basic-flex-row;
}

.main-content {
  @include basic-flex-column;

  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  .main-frame-title {
    font-size: 22px;
    font-weight: 600;
  }
  width: 90vw;
  padding: 2rem;
  background-color: $main-bg-color;

  /* Container for chart (Card) */
  .chart-container{
    min-width: 31%;
    min-height: 20rem;
    max-height: 45%;
    margin-right: 1.5%;
  }

  /* Content of aforementionned Card */
  .chart-container-content {
    min-width: inherit;
    height: 75%;
    max-height: 75%;
    padding: 0.5rem;
  }

  .options-container{
    min-width: 31%;
    min-height: 20rem;
    max-height: 45%;
    margin-right: 1.5%;
  }

  .sliders-container {
    min-width: 35%;
    min-height: 20rem;
    max-height: 45%;
  }

  /* Content of aforementionned Card */
  .options-container-content {
    min-width: inherit;
    height: 75%;
    max-height: 75%;
    padding: 1.8rem;
  }

  .toggle-container {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 3% 0% 5% 0%;
  }

  .sliders-mtt {
    @include sliders($sliders-colors, $sliders-colors);

    .slider-title {
      font-weight: bold;
      margin: 10px 0;
      font-size: 14px;
    }

    min-width: 25%;
    margin: 3% 0%;
    font-size: 80%;
  }
}
