.access-restrict-main {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 90vh;

    .access-restrict-child {
        margin-top: 0.5rem;
    }

    .access-restrict-icon {
        color: #80808080;
        font-size: 6rem;
    }
}