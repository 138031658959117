@import '../../globalMixins.scss';
@import '../../mixins.scss';

.filter-drawer-cls {
    .arrow-button {
        @include drawer-arrows;
        margin-top: 15px;
    }
}

.icon-margin {
    margin-top: 25px;
}

.button-margin {
    width: 100%;
    margin-top: 20px;
}

.filter-drawer-opened {
    @include drawer-opened;
    @include for-desktop-up {
        max-width: 27vw;
    }
    @include for-big-desktop-up {
        max-width: 20vw;
    }
    margin-top: 15%;
    .submit-btn {
        margin-top: 20px;
    }
}