.generic-table-controls-cls {
    &.content-right {
        float: right;
    }

    .icon {
        margin-left: 0px;
        svg {
            vertical-align: middle;
        }
    }

}
