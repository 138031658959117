.task-report-container-cls {
    background-color: #f7f9fa;
    padding-top: 10px;

    .api-fetching-error {
        color: #f54a07;
        font-size: 1rem;
        background-color: #FFFFFF;
    }

    .back-button {
        border: none;
        padding: 3px;
    }
}