.filter-drawer-combo {
    margin: 5px 0px;

    .displayed-text {
        padding: 0px 4%;
    }

    .combobox-label {
        display: flex;
        justify-content: space-between;
    }
}